//面板影藏事件
const HIDE="hide";
//下拉面板显示
const SHOW="show";
//值改变事件
const ONCHANGE="comboTreeOnChange";
//双向绑定用到的key
const updateKey='update:modelValue';
import {ref, computed, getCurrentInstance, reactive, toRefs, onBeforeMount,onMounted, defineComponent, watch, provide,nextTick} from 'vue';
import ComboTreeUtil,{IComboTreeDataObj} from "@/components/base_comp/comboTree/comboTreeUtil";
export default defineComponent({
    name: "comboTree",
    props: {
        modelValue: {//表示该自定义控件的值，必须和上面updateKey里面的名称保持一样
            type: String
        },
        disabled:{type:Boolean,default:false},
        bindEvent:{type:Object,default:()=>{return{}}}
    },
    setup(props,context){
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils;
        let comboParam:any=context.attrs.params;
        let dataObj:IComboTreeDataObj=reactive<IComboTreeDataObj>({
            utilInst:{}as any,
            refMap:new Map(),
            visible:false,//popover的显示/影藏控制字段
            readOnly:false,//输入框是否只读（是否可以搜索）
            comboText: "",//下拉显示文本
            loading:false,//加载效果控制字段

            mapTreeValueText:new Map(),//有复选框的时候，key为树节点的值，value为树节点的显示文本
            isLoaded: false,//预防下拉框值改变不停的去后台查询的变量标志
            modelMethod:comboParam.modelMethod,//下拉的请求路径
            data: undefined,//树的数据集
            defaultProps: {
                label: 'label',
                children: 'children'
            },
            baseParams:{
                expandedKeys:[],//需要展开的树节点ID集合
                expandAll:false,
                showCheckbox:false,
                checkOnClick:true,
                isOnlySelectLeaf:true
            },//默认的下拉框参数
            comboParam:comboParam,
            otherParams:{
                popoverWidth:400
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ComboTreeUtil(proxy,dataObj,props,context)
            dataObj.utilInst.init(comboParam);//如果放到了onMounted里面，如果有复选框的时候，要想展开所有节点不会生效
        })
        onMounted(()=>{
            nextTick(async()=>{
                if(props.modelValue && !dataObj.comboText){//可编辑表格点击行显示下拉的时候，需要根据id查询显示文本
                    await dataObj.utilInst.getTextById(props.modelValue,comboParam);
                    dataObj.baseParams.expandedKeys.push(props.modelValue);
                }
                let inputGroup:any=document.querySelector('.el-input-group');
                if(inputGroup && inputGroup.clientWidth && inputGroup.clientWidth>200){
                    dataObj.otherParams.popoverWidth=inputGroup.clientWidth;
                }
            })
        })
        watch(() => context.attrs.params,(newValue,oldValue) => {
            dataObj.utilInst.init(newValue);
        })
        watch(() => props.modelValue,async (newValue,oldValue) => {
            if(newValue){
                if(!dataObj.comboText)await dataObj.utilInst.getTextById(props.modelValue,comboParam);
                // proxy.$parent.$parent.validate(newValue);//验证所有字段
                if(comboParam.formRef)await comboParam.formRef.validateField(comboParam.comboId);//值验证该控件id的字段
            }else{
                dataObj.comboText = '';
            }
            context.emit(updateKey,newValue);
            await dataObj.utilInst.emitEvent(ONCHANGE, newValue, oldValue)//触发事件
        })
        //得到树引用实例
        const getTreeInst=()=>{
            return dataObj.refMap.get('comboTreeRef');
        }
        //-----------------------过滤搜索树----------------------
        //树本身节点过滤方法
        const filterNode=(value:any, data:any)=>{
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        }
        //搜索过滤树节点事件
        const inputChange=(val:string)=>{
            dataObj.refMap.get('comboTreeRef').filter(val);
        }
        //-----------------------过滤搜索树----------------------
        //点击树节点事件，设置下拉树的显示文本和值
        const handleNodeClick=(data:any)=>{
            //该方法只会处理不显示复选框的时候，要复选框的情况在下方的checkChange来处理
            if(!dataObj.baseParams.showCheckbox){
                //如果只能操作叶子节点，那么要判断点击节点是否为叶子节点
                if(dataObj.baseParams.isOnlySelectLeaf) {
                    if (!data.children) {//如果只能选择叶子节点，则如果点击的是非叶子节点就不管了
                        dataObj.comboText=data.label;
                        context.emit(updateKey,data.value);
                        dataObj.refMap.get('input').$el.click();
                    }
                }else{
                    dataObj.comboText=data.label;
                    context.emit(updateKey,data.value);
                    dataObj.refMap.get('input').$el.click();
                }
            }
        }
        //复选框改变事件，注意：单击节点也会触发该事件，即便没有显示复选框，单击节点也会调用该方法
        const checkChange=(data:any, checked:any, indeterminate:any)=>{
            if(!dataObj.baseParams.showCheckbox)return;//如果没有显示复选框，说明是点击节点调用的该方法
            //如果要显示复选框，复选框发生选中状态变化的时候，把值从mapTreeValueText存入或者移除
            if(checked){//选中复选框，把选中节点放到到map集合中
                if(!dataObj.mapTreeValueText.has(data.value)){//如果mapTreeValueText没有记录点击的节点
                    if(dataObj.baseParams.isOnlySelectLeaf){
                        if(!data.children){
                            dataObj.mapTreeValueText.set(data.value,data.label);
                        }
                    }else{
                        dataObj.mapTreeValueText.set(data.value,data.label);
                    }
                }
            }else{//取消选中复选框，把取消选中的节点从mapTreeValueText集合中去除
                if(dataObj.mapTreeValueText.has(data.value))dataObj.mapTreeValueText.delete(data.value);
            }
            //根据mapTreeValueText中的值为下拉框赋值
            dataObj.comboText=[...dataObj.mapTreeValueText.values()].join(',');
            context.emit(updateKey,[...dataObj.mapTreeValueText.keys()].join(','));//触发事件，改变下拉框的值
        }
        //清除输入框事件
        const inputClear=()=>{
            //如果要显示复选框，需要把mapTreeValueText里面存储的树节点给清除掉
            if(dataObj.baseParams.showCheckbox)dataObj.mapTreeValueText.clear();
            //清除显示文本
            dataObj.comboText='';
            context.emit(updateKey,'');//触发事件，改变下拉框的值
        }
        //下拉框影藏事件
        const hide=async ()=>{
            await dataObj.utilInst.emitEvent(HIDE)//触发事件
        }
        //下拉框显示事件
        const show=async()=>{
            dataObj.loading=true;//开启正在加载效果
            if(!dataObj.data){//如果展开面板的时候，下拉框已经有值了，那么就不用去后台查询了，因为可能是静态树或者在父类已经准备好数据了
                let modelPath=dataObj.modelMethod+ "/custom";
                let res = await utils.Api.postRequest({ url: modelPath, params: Object.assign({operateType:'tree'},dataObj.baseParams) });
                dataObj.data=res.data;
            }
            //如果要显示复选框，那么在下拉框有值的时候，要把下拉框相应值给勾选上
            if(dataObj.baseParams.showCheckbox){
                if(props.modelValue){
                    // console.log(props.modelValue.split(",").map(Number));
                    let _modelValue:any=props.modelValue;
                    dataObj.refMap.get('comboTreeRef').setCheckedKeys(_modelValue.split(","));
                }else{
                    dataObj.refMap.get('comboTreeRef').setCheckedKeys([]);
                }
            }
            await dataObj.utilInst.emitEvent(SHOW)//触发事件
            if (props.modelValue) {
                dataObj.baseParams.expandedKeys.length=0;
                dataObj.baseParams.expandedKeys.push(props.modelValue);
            }
            dataObj.loading=false;//关闭正在加载效果
        }
        return{
            ...toRefs(dataObj),getTreeInst,filterNode,inputChange,
            handleNodeClick,checkChange,inputClear,hide,show
        }
    },

    components: {}
});